export interface Task {
  id: number;
  name: string;
  orderIndex: number
  description: string;
  dueDate: string;
  taskBoard: string;
  priority: 'LOW' | 'MEDIUM' | 'HIGH';
  status?: 'TODO' | 'IN_PROGRESS' | 'DONE';
}

export interface Column {
  id: string;
  title: string;
  tasks: Task[];
}

export interface DragResult {
  source: {
    droppableId: string;
    index: number;
  };
  destination?: {
    droppableId: string;
    index: number;
  };
}

export enum TaskBoardCategories {
    VENUE_AND_CEREMONY,
    WEDDING_WEBSITES,
    PHOTO_AND_VIDEOS,
    FOOD_AND_DRINKS,
    ATTIRE,
    MUSIC,
    FLOWERS_AND_DECOR,
    INVITATIONS,
    PLANNING_AND_ADMIN,
    HONEYMOON
}