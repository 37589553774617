import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { DesktopDesign } from '../../../components/design/desktopDesign.tsx';
import { MobileDesign } from '../../../components/design/mobileDesign.tsx';
import getImageUrl from '../../../utils/imageUtils';
import { getAuth } from 'firebase/auth';
import { fetchUserCredits, updateCreditsAfterGeneration } from '../../../services/creditsService';
import { generateToken } from '../../../services/shared/generateToken';
import { generateImage, ModelOptions } from '../../../services/api/designApi';
import { uploadBookmark } from '../../../services/api/bookmarkApi';
import { useSharedDesignState } from '../../../hooks/useSharedDesignState';
import creditEventEmitter from '../../../components/credits/creditEventEmitter';

const WeddingCakes = () => {
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  // Use shared state
  const {
    selections,
    subSelections,
    previewItems,
    setSelections,
    setSubSelections,
    setPreviewItems,
  } = useSharedDesignState('Cake');

  // Local state
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState('');
  const [credits, setCredits] = useState(null);
  const [showCreditsModal, setShowCreditsModal] = useState(false);

  // Define steps for the design process
  const steps = [
    {
      id: 'tiers',
      title: 'Select Number of Tiers',
      type: 'grid',
      options: [
        { name: 'One-Tier', image: getImageUrl('cake/onetiercake.jpg') },
        { name: 'Two-Tier', image: getImageUrl('cake/twotiercake.jpg') },
        { name: 'Three-Tier', image: getImageUrl('cake/threetiercake.jpg') },
        { name: 'Four-Tier', image: getImageUrl('cake/fourtiercake.jpg') },
      ],
      allowCustom: true,
    },
    {
      id: 'shape',
      title: 'Choose Your Shape',
      type: 'grid',
      options: [
        { name: 'Round', image: getImageUrl('cake/circle.jpg') },
        { name: 'Square', image: getImageUrl('cake/square.jpg') },
        { name: 'Heart', image: getImageUrl('cake/heart.jpg') },
      ],
      allowCustom: true,
    },
    {
      id: 'decoration',
      title: 'Choose Your Decoration',
      type: 'grid',
      options: [
        {
          name: 'Shards',
          image: getImageUrl('cake/shard.jpg'),
          subOptions: ['Chocolate', 'Sugar', 'Mirror Glaze'],
        },
        {
          name: 'Drips',
          image: getImageUrl('cake/drip.jpg'),
          subOptions: ['Chocolate', 'Caramel', 'Colored Ganache'],
        },
        {
          name: 'Fruits',
          image: getImageUrl('cake/fruit.jpg'),
          subOptions: ['Berries', 'Citrus', 'Tropical'],
        },
        {
          name: 'Floral',
          image: getImageUrl('cake/floral.jpg'),
          subOptions: ['Roses', 'Peonies', 'Wildflowers'],
        },
      ],
      allowCustom: true,
    },
    {
      id: 'texture',
      title: 'Choose Texture and Finish',
      type: 'grid',
      options: [
        { name: 'Smooth', image: '/cake/smooth.jpg' },
        { name: 'Textured', image: '/cake/textured.jpg' },
        { name: 'Glossy', image: '/cake/glossy.jpg' },
        { name: 'Matte', image: '/cake/matte.jpg' },
      ],
      allowCustom: true,
    },
    {
      id: 'ribbon',
      title: 'Choose Ribbon Style',
      type: 'grid',
      options: [
        { name: 'None', image: '/cake/none.jpg' },
        { name: 'Satin', image: '/cake/satin.jpg' },
        { name: 'Lace', image: '/cake/lace.jpg' },
        { name: 'Hessian', image: '/cake/hessian.jpg' },
        { name: 'Diamante', image: '/cake/diamante.jpg' },
        { name: 'Pearls', image: '/cake/pearls.jpg' },
      ],
      allowCustom: true,
    },
    {
      id: 'color',
      title: 'Choose Your Color',
      type: 'color-picker',
      options: [
        { name: 'White', hex: '#FFFFFF' },
        { name: 'Pearl White', hex: '#F0EAD6' },
        { name: 'Ivory', hex: '#FFFFF0' },
        { name: 'Black', hex: '#000000' },
        { name: 'Gold', hex: '#dcc282' },
        { name: 'Rose Gold', hex: '#B76E79' },
        { name: 'Silver', hex: '#C0C0C0' },
        { name: 'Copper', hex: '#B87333' },
        { name: 'Blush Pink', hex: '#FFB6C1' },
        { name: 'Sage Green', hex: '#9DC183' },
        { name: 'Eucalyptus', hex: '#44D7A8' },
        { name: 'Olive Green', hex: '#808000' },
        { name: 'Navy Blue', hex: '#000080' },
        { name: 'French Blue', hex: '#0072B5' },
        { name: 'Slate Blue', hex: '#6A5ACD' },
        { name: 'Dusty Blue', hex: '#6699CC' },
        { name: 'Periwinkle', hex: '#CCCCFF' },
        { name: 'Burgundy', hex: '#800020' },
        { name: 'Mulberry', hex: '#C54B8C' },
        { name: 'Plum', hex: '#8E4585' },
        { name: 'Champagne', hex: '#F7E7CE' },
        { name: 'Lavender', hex: '#E6E6FA' },
        { name: 'Mauve', hex: '#E0B0FF' },
        { name: 'Terracotta', hex: '#E2725B' },
        { name: 'Rust', hex: '#B7410E' },
        { name: 'Gray', hex: '#808080' },
        { name: 'Taupe', hex: '#483C32' },
        { name: 'Emerald', hex: '#50C878' },
        { name: 'Coral', hex: '#FF7F50' },
        { name: 'Mint', hex: '#98FF98' },
        { name: 'Peach', hex: '#FFE5B4' },
      ],
    },
  ];

  const handleSelectionChange = (stepId, value) => {
    setSelections((prev) => ({ ...prev, [stepId]: value }));
  };

  const handleSubSelectionChange = (stepId, value) => {
    setSubSelections((prev) => ({ ...prev, [stepId]: value }));
  };

  const generateVisualizations = async () => {
    const isAllSelectionsComplete = steps.every((step) => selections[step.id]);

    if (!isAllSelectionsComplete) {
      setError('Please complete all design options');
      return;
    }

    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      setError('Please log in to generate designs');
      return;
    }

    const userCredits = await fetchUserCredits(currentUser.uid);
    if (userCredits < 30) {
      setShowCreditsModal(true);
      setError('Out of credits, Please Purchase Credits');
      return;
    }

    setLoading(true);
    setProgress(0);
    setError('');

    try {
      let tierDescription = '';
      switch(selections.tiers) {
        case 'One-Tier':
          tierDescription = 'A single-layer wedding cake with substantial height, featuring clean, straight sides and perfectly smooth edges. The layer is tall and elegant, with crisp, sharp angles where the top meets the sides';
          break;
        case 'Two-Tier':
          tierDescription = 'A wedding cake with two distinct layers, each perfectly level and sharply defined. The bottom layer is wide and substantial, while the top layer is precisely centered and slightly smaller, creating clean, geometric lines between the layers. Each layer has immaculate, straight sides and razor-sharp edges';
          break;
        case 'Three-Tier':
          tierDescription = 'A wedding cake composed of three pristine layers, each one meticulously stacked and perfectly level. Each layer has exact straight sides and crisp, sharp edges, with the layers graduating in size from a wide, substantial bottom layer, through a proportional middle layer, to an elegant smaller top layer. The transitions between layers are precise and geometric';
          break;
        case 'Four-Tier':
          tierDescription = 'A grand wedding cake featuring four immaculate layers, each one precisely stacked and perfectly level. Every layer has exact straight sides and razor-sharp edges, graduating harmoniously in size from a substantial base layer, through two perfectly proportioned middle layers, to an elegant top layer. The transitions between layers are crisp and geometric, creating a striking architectural silhouette';
          break;
        default:
          tierDescription = `A ${selections.tiers} wedding cake`;
      }
      
      const decorationSubOptions = subSelections.decoration ? `with ${subSelections.decoration} details` : '';
      const textureDescription = selections.texture ? `and a ${selections.texture} finish` : '';
      const ribbonDescription = selections.ribbon !== 'None' ? `, accented with ${selections.ribbon} ribbon` : '';
      
      const cakePrompt = `A professional photograph of ${tierDescription}, ${selections.shape}-shaped, elegantly decorated with ${selections.decoration} ${decorationSubOptions}, in a ${selections.color} color scheme ${textureDescription}${ribbonDescription}. The cake is displayed on a clean, well-lit surface, showcasing its full design in a single, centered view. The image is high-resolution, capturing the cake's elegance and sophistication with sharp details and perfect lighting.`;

      setProgress(10);
      const token = await generateToken();

      const cakeImageUrl = await generateImage(cakePrompt, token, currentUser.uid, ModelOptions.PHOENIX);

      setPreviewItems([{ url: cakeImageUrl, label: 'Wedding Cake', prompt: cakePrompt }]);

      setProgress(100);

      const updatedCredits = await updateCreditsAfterGeneration(currentUser.uid);
      creditEventEmitter.emit(updatedCredits);
      setCredits(updatedCredits);
    } catch (error) {
      console.error('Error generating visualizations:', error);
      setError('Failed to generate visualizations');
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (item) => {
    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;

      if (!currentUser) {
        setError('Please log in to save designs');
        return;
      }

      await uploadBookmark(item, 'Cake', selections, currentUser);
    } catch (error) {
      console.error('Error saving design:', error);
      setError('Failed to save design');
    }
  };

  return (
    <>
      {isMobile ? (
        <MobileDesign
          steps={steps}
          selections={selections}
          title="Design Your Wedding Cake"
          subSelections={subSelections}
          previewItems={previewItems}
          loading={loading}
          progress={progress}
          error={error}
          credits={credits}
          onSelectionChange={handleSelectionChange}
          onSubSelectionChange={handleSubSelectionChange}
          onGenerate={generateVisualizations}
          onSave={handleSave}
        />
      ) : (
        <DesktopDesign
          steps={steps}
          title="Design Your Wedding Cake"
          selections={selections}
          subSelections={subSelections}
          previewItems={previewItems}
          loading={loading}
          progress={progress}
          error={error}
          credits={credits}
          onSelectionChange={handleSelectionChange}
          onSubSelectionChange={handleSubSelectionChange}
          onGenerate={generateVisualizations}
          onSave={handleSave}
        />
      )}
    </>
  );
};

export default WeddingCakes;