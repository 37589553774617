import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from '../../utils/firebase';
import { AuthAPI } from '../../services/api/authAPI';
import { FaBuilding, FaMapMarkerAlt, FaPhone } from 'react-icons/fa';
import { 
  getAuth, 
  createUserWithEmailAndPassword, 
} from 'firebase/auth';
import { getFirestore, doc, setDoc } from 'firebase/firestore';

const LoginPage = () => {
  const [activeTab, setActiveTab] = useState('signin');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [locationName, setLocationName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userType, setUserType] = useState(null);
  const [error, setError] = useState('');
  const [showError, setShowError] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const [isVendorSignUp, setIsVendorSignUp] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (error) {
      setShowError(true);
      const timer = setTimeout(() => {
        setShowError(false);
        setError('');
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [error]);

  /**
   * Handles user sign-in with email/password
   * 1. Prevents default form submission
   * 2. Clears any existing errors
   * 3. Attempts to sign in via AuthAPI
   * 4. Redirects to home on success, shows error on failure
   */
  const handleSignIn = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      await AuthAPI.signInWithEmail(email, password);
      navigate('/');
    } catch (error) {
      console.error('Sign in error:', error);
      setError(error.message);
    }
  };

  /**
   * Handles new user registration
   * 1. Validates Terms of Service acceptance
   * 2. Constructs user data object based on user type (vendor/couple)
   * 3. Sends registration request to AuthAPI
   * 4. Shows success message and redirects after delay
   * 5. Handles any registration errors
   */
  const handleSignUp = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      if (!acceptedTerms) {
        setError('Please accept the Terms of Service to continue');
        return;
      }

      const apiUserData = {
        emailAddress: email,
        venderType: userType === 'vendor' ? 'vendor' : null,
        companyName: userType === 'vendor' ? companyName : null,
        phoneNumber: userType === 'vendor' ? phoneNumber : null,
        location: userType === 'vendor' ? locationName : null,
        wedify_user_type: userType === 'vendor' ? 'vendor' : 'couple'
      };

      const userData = {
        email,
        userType,
        ...(userType === 'vendor' && {
          companyName,
          locationName,
          phoneNumber
        })
      };

      await AuthAPI.signUp(email, password, userData, apiUserData);
      // Important: Wait for the document to be created before redirecting
      if (userType === 'vendor') {
        console.log('Redirecting to vendor onboarding...');
        navigate('/vendor-onboarding', { replace: true });
      } else {
        navigate('/', { replace: true });
      }

    } catch (error) {
      console.error('Sign up error:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handles password reset requests
   * 1. Validates email is provided
   * 2. Sends password reset email via AuthAPI
   * 3. Shows success/error message to user
   */
  const handleForgotPassword = async () => {
    if (!email) {
      setError('Please enter your email address');
      return;
    }

    try {
      await AuthAPI.sendPasswordReset(email);
      setError('✓ Password reset email sent! Check your inbox.');
    } catch (error) {
      console.error('Password reset error:', error);
      setError(error.message);
    }
  };

  /**
   * Handles Google OAuth sign-in
   * 1. Attempts to sign in with Google via AuthAPI
   * 2. Checks if user exists in database
   * 3. Redirects to home on success
   * 4. Handles various OAuth error cases with specific messages
   */
  const handleGoogleSignIn = async () => {
    try {
      const { result, userDoc } = await AuthAPI.signInWithGoogle();

      if (!userDoc.exists() && !activeTab === 'signup') {
        setError('This Google account is not registered. Please sign up first.');
        await auth.signOut();
        return;
      }

      // If signing up and user doesn't exist, create the user
      if (activeTab === 'signup' && !userDoc.exists()) {
        const userData = {
          email: result.user.email,
          userType: userType || 'couple'
        };

        const apiUserData = {
          emailAddress: result.user.email,
          venderType: userType === 'vendor' ? 'vendor' : null,
          wedify_user_type: userType === 'vendor' ? 'vender' : 'couple'
        };

        try {
          await AuthAPI.signUp(result.user.email, null, userData, apiUserData);
        } catch (error) {
          console.error('Failed to create user profile:', error);
          setError('Failed to complete signup. Please try again.');
          await auth.signOut();
          return;
        }
      }

      navigate('/');
    } catch (error) {
      console.error('Google Sign-In error:', error);
      switch (error.code) {
        case 'auth/account-exists-with-different-credential':
          setError('An account already exists with this email address but with a different sign-in method.');
          break;
        case 'auth/popup-closed-by-user':
          setError('Sign-in was cancelled. Please try again.');
          break;
        case 'auth/popup-blocked':
          setError('Sign-in popup was blocked by your browser. Please allow popups for this site.');
          break;
        case 'auth/user-disabled':
          setError('This account has been disabled. Please contact support.');
          break;
        default:
          setError('Failed to sign in with Google. Please try again.');
      }
    }
  };

  return (
    <div className="min-h-screen bg-[#FFF5F7] flex items-center justify-center p-4 sm:p-6 md:p-8 overflow-y-auto">
      <div className="w-full max-w-md bg-white rounded-3xl shadow-lg p-4 sm:p-6 md:p-8">
        {/* Tabs */}
        <div className="flex mb-6 sm:mb-8 border-b">
          <button
            className={`flex-1 py-2 text-sm sm:text-base font-normal transition-colors duration-300 border-b-2
              ${activeTab === 'signin' 
                ? 'text-[#FF4D8D] border-[#FF4D8D]' 
                : 'text-gray-400 border-transparent'}`}
            onClick={() => {
              setActiveTab('signin');
              setUserType(null);
              setIsVendorSignUp(false);
            }}
          >
            Log in
          </button>
          <button
            className={`flex-1 py-2 text-sm sm:text-base font-normal transition-colors duration-300 border-b-2
              ${activeTab === 'signup' 
                ? 'text-[#FF4D8D] border-[#FF4D8D]' 
                : 'text-gray-400 border-transparent'}`}
            onClick={() => {
              setActiveTab('signup');
              setUserType(null);
              setIsVendorSignUp(false);
            }}
          >
            Sign Up
          </button>
          <button
            className={`flex-1 py-2 text-sm sm:text-base font-normal transition-colors duration-300 border-b-2
              ${activeTab === 'vendor' 
                ? 'text-[#FF4D8D] border-[#FF4D8D]' 
                : 'text-gray-500 border-transparent hover:text-[#FF4D8D] hover:border-[#FF4D8D]'}`}
            onClick={() => {
              setActiveTab('vendor');
              setUserType('vendor');
              setIsVendorSignUp(true);
            }}
          >
            Vendor
          </button>
        </div>

        {/* Error Message */}
        {showError && (
          <div className={`mb-3 p-2 rounded-lg text-center ${
            error && error.includes('✓') ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
          }`}>
            {error}
          </div>
        )}

        {/* Sign In Form */}
        {activeTab === 'signin' && !isVendorSignUp && (
          <form onSubmit={handleSignIn} className="space-y-3">
            <div className="space-y-1">
              <h2 className="text-lg sm:text-xl font-medium text-gray-800">Welcome Back</h2>
         
            </div>

            <div className="space-y-2">
              <div className="space-y-1">
                <label className="block text-sm font-medium text-gray-700">Email Address</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-3 py-2 text-gray-700 border border-gray-200 rounded-xl focus:outline-none focus:border-[#FF4D8D]"
                  placeholder="Enter your email"
                  required
                />
              </div>

              <div className="space-y-1">
                <label className="block text-sm font-medium text-gray-700">Password</label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-3 py-2 text-gray-700 border border-gray-200 rounded-xl focus:outline-none focus:border-[#FF4D8D]"
                  placeholder="Enter your password"
                  required
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  className="rounded border-gray-300 text-[#FF4D8D] focus:ring-[#FF4D8D]"
                />
                <span className="ml-2 text-sm text-gray-600">Remember me</span>
              </label>
              <button
                type="button"
                onClick={handleForgotPassword}
                className="text-sm text-[#FF4D8D] hover:text-[#FF69B4] font-normal"
              >
                Forgot password?
              </button>
            </div>

            <button
              type="submit"
              className="w-full py-0.5 text-sm bg-[#FF4D8D] text-white hover:bg-[#FF69B4] transition-colors"
            >
              Log in
            </button>
            <div className="relative my-3">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-200"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">or</span>
              </div>
            </div>

            <button
              type="button"
              onClick={handleGoogleSignIn}
              className="w-full flex items-center justify-center space-x-2 py-1.5 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
            >
              <img
                src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                alt="Google"
                className="w-4 h-4"
              />
              <span className="text-sm text-gray-600">Log in with Google</span>
            </button>
          </form>
        )}

        {/* Regular Sign Up Form */}
        {activeTab === 'signup' && !isVendorSignUp && (
          <div className="space-y-3">
            <div className="space-y-1">
              <h2 className="text-lg sm:text-xl font-medium text-gray-800">Create your account</h2>
            </div>

            <form onSubmit={handleSignUp} className="space-y-3">
              <div className="space-y-2">
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">Email Address</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-3 py-2 text-gray-700 border border-gray-200 rounded-xl focus:outline-none focus:border-[#FF4D8D]"
                    placeholder="Enter your email"
                    required
                  />
                </div>

                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">Password</label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-3 py-2 text-gray-700 border border-gray-200 rounded-xl focus:outline-none focus:border-[#FF4D8D]"
                    placeholder="Create a password"
                    required
                  />
                </div>
              </div>

              <div className="flex items-center gap-2">
                <input 
                  type="checkbox" 
                  id="terms"
                  className="mt-0.5"
                  checked={acceptedTerms}
                  onChange={(e) => setAcceptedTerms(e.target.checked)}
                />
                <label className="text-sm text-gray-600">
                  I accept the {' '}
                  <Link to="/terms-of-service" className="text-[#FF4D8D] hover:text-[#FF69B4]">
                    Terms of Service
                  </Link>
                  {' '} and {' '}
                  <Link to="/privacy-policy" className="text-[#FF4D8D] hover:text-[#FF69B4]">
                    Privacy Policy
                  </Link>
                </label>
              </div>

              <button
                type="submit"
                className="w-full py-0.5 text-sm bg-[#FF4D8D] text-white hover:bg-[#FF69B4] transition-colors"
              >
                Sign up
              </button>

              <div className="relative my-3">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-200"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">or</span>
                </div>
              </div>

              <button
                type="button"
                onClick={handleGoogleSignIn}
                className="w-full flex items-center justify-center space-x-2 py-1.5 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
              >
                <img
                  src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                  alt="Google"
                  className="w-4 h-4"
                />
                <span className="text-sm text-gray-600">Continue with Google</span>
              </button>

              <p className="text-center text-xs text-gray-600">
                Already have an account?{' '}
                <button
                  onClick={() => setActiveTab('signin')}
                  className="text-[#FF4D8D] hover:text-[#FF69B4]"
                >
                  Log in
                </button>
              </p>
            </form>
          </div>
        )}

        {/* Vendor Sign Up Form */}
        {isVendorSignUp && (
          <div className="flex flex-col space-y-6 pb-8">
            <div className="space-y-1">
              <h2 className="text-lg sm:text-xl font-medium text-gray-800">
                Create Vendor Account
              </h2>
            </div>

            <form onSubmit={handleSignUp} className="space-y-3">
              <div className="space-y-2">
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Company Name
                  </label>
                  <div className="relative">
                    <FaBuilding className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                      type="text"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      className="w-full pl-10 pr-3 py-2 text-gray-700 border border-gray-200 rounded-xl focus:outline-none focus:border-[#FF4D8D]"
                      placeholder="Enter company name"
                      required
                    />
                  </div>
                </div>

                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Location
                  </label>
                  <div className="relative">
                    <FaMapMarkerAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                      type="text"
                      value={locationName}
                      onChange={(e) => setLocationName(e.target.value)}
                      className="w-full pl-10 pr-3 py-2 text-gray-700 border border-gray-200 rounded-xl focus:outline-none focus:border-[#FF4D8D]"
                      placeholder="Enter location"
                      required
                    />
                  </div>
                </div>

                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Phone Number
                  </label>
                  <div className="relative">
                    <FaPhone className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                      type="tel"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      className="w-full pl-10 pr-3 py-2 text-gray-700 border border-gray-200 rounded-xl focus:outline-none focus:border-[#FF4D8D]"
                      placeholder="Enter phone number"
                      required
                    />
                  </div>
                </div>

                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Email Address
                  </label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-3 py-2 text-gray-700 border border-gray-200 rounded-xl focus:outline-none focus:border-[#FF4D8D]"
                    placeholder="Enter your email"
                    required
                  />
                </div>

                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Password
                  </label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-3 py-2 text-gray-700 border border-gray-200 rounded-xl focus:outline-none focus:border-[#FF4D8D]"
                    placeholder="Create a password"
                    required
                  />
                </div>
              </div>

              <div className="space-y-6">
                <div className="flex items-center gap-2">
                  <input 
                    type="checkbox" 
                    id="vendor-terms"
                    className="mt-0.5"
                    checked={acceptedTerms}
                    onChange={(e) => setAcceptedTerms(e.target.checked)}
                  />
                  <label className="text-sm text-gray-600">
                    I accept the {' '}
                    <Link to="/terms-of-service" className="text-[#FF4D8D] hover:text-[#FF69B4]">
                      Terms of Service
                    </Link>
                    {' '} and {' '}
                    <Link to="/privacy-policy" className="text-[#FF4D8D] hover:text-[#FF69B4]">
                      Privacy Policy
                    </Link>
                  </label>
                </div>

                <button type="submit" className="w-full py-0.5 text-sm bg-[#FF4D8D] text-white hover:bg-[#FF69B4] transition-colors">
                  Create Vendor Account
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginPage;