import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { Task } from './types';
import './styles.css'
import { createPortal } from 'react-dom';


interface AddTaskModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAddTask: (task: Partial<Task>) => Promise<void>;
  editTask: Task | null;
  selectedCategory: string;
  showCategoryDropdown: boolean;
}

export const AddTaskModal: React.FC<AddTaskModalProps> = ({ editTask, isOpen, onClose, onAddTask, selectedCategory, showCategoryDropdown }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [priority, setPriority] = useState<'LOW' | 'MEDIUM' | 'HIGH'>('MEDIUM');
  const [dueDate, setDueDate] = useState('');
  const [selectedTaskCategory, setSelectedTaskCategory] = useState(selectedCategory);

  const categories = [
    { id: 'venue', title: 'Venue & Ceremony', icon: '🏰' },
    { id: 'website', title: 'Wedding Website', icon: '💻' },
    { id: 'photo', title: 'Photos & Videos', icon: '📸' },
    { id: 'food', title: 'Food & Drink', icon: '🍽️' },
    { id: 'attire', title: 'Attire', icon: '👗' },
    { id: 'music', title: 'Music', icon: '🎵' },
    { id: 'flowers', title: 'Flowers & Decor', icon: '💐' },
    { id: 'invites', title: 'Invitations & Paper', icon: '✉️' },
    { id: 'planning', title: 'Planning & Admin', icon: '📋' },
    { id: 'honeymoon', title: 'Honeymoon', icon: '✈️' },
  ];
  
  
  useEffect(() => {
    setName(editTask ? editTask.name : '');
    setDescription(editTask ? editTask.description : '');
    setPriority(editTask ? editTask.priority : 'MEDIUM');
    setDueDate(editTask ? editTask.dueDate || '' : '');
  }, [editTask]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onAddTask({
      name,
      description,
      priority,
      dueDate,
      id: editTask?.id,
      taskBoard: showCategoryDropdown ? selectedTaskCategory : selectedCategory
    });
    setName('');
    setDescription('');
    setPriority('MEDIUM');
    setDueDate('');
    onClose();
  };

  if (!isOpen) return null;

  return createPortal(
    <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex sm:items-center justify-center z-50 p-4 sm:p-6">
      <div className="w-full max-w-md relative bg-gradient-to-b from-[#F8E8FF] to-[#E8F0FF] rounded-3xl shadow-lg overflow-hidden backdrop-blur-md mt-[10vh] sm:mt-0">
        <div className="absolute inset-0 bg-white/40 backdrop-blur-md rounded-3xl" />
        <div className="relative p-6">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-xl font-medium text-gray-800">Add New Task</h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600 transition-colors"
            >
              <X className="w-5 h-5" />
            </button>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Title
              </label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full px-3 py-2 bg-white/90 border border-pink-100/50 rounded-2xl focus:outline-none focus:ring-2 focus:ring-[#FF4D8D]/20 focus:border-[#FF4D8D]/30 transition-all text-gray-700 backdrop-blur-sm"
                placeholder="Enter task title"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Description
              </label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="w-full px-3 py-2 bg-white/90 border border-pink-100/50 rounded-2xl focus:outline-none focus:ring-2 focus:ring-[#FF4D8D]/20 focus:border-[#FF4D8D]/30 transition-all text-gray-700 backdrop-blur-sm"
                rows={3}
                placeholder="Enter task description"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Priority
              </label>
              <div className="relative">
                <select
                  value={priority}
                  onChange={(e) => setPriority(e.target.value as 'LOW' | 'MEDIUM' | 'HIGH')}
                  className="w-full px-3 py-2 bg-white/90 border border-pink-100/50 rounded-2xl focus:outline-none focus:ring-2 focus:ring-[#FF4D8D]/20 focus:border-[#FF4D8D]/30 transition-all appearance-none cursor-pointer text-gray-700 backdrop-blur-sm"
                  style={{
                    WebkitAppearance: 'none',
                    MozAppearance: 'none',
                    backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e")`,
                    backgroundPosition: 'right 0.5rem center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '1.5em 1.5em',
                    paddingRight: '2.5rem'
                  }}
                >
                  <option value="" disabled hidden>Select priority</option>
                  <option value="LOW">Low</option>
                  <option value="MEDIUM">Medium</option>
                  <option value="HIGH">High</option>
                </select>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Due Date
              </label>
              <input
                type="date"
                value={dueDate}
                onChange={(e) => setDueDate(e.target.value)}
                className="w-full px-3 py-2 bg-white/90 border border-pink-100/50 rounded-2xl focus:outline-none focus:ring-2 focus:ring-[#FF4D8D]/20 focus:border-[#FF4D8D]/30 transition-all text-gray-700 backdrop-blur-sm"
                required
              />
            </div>

            {showCategoryDropdown && (
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Category
                </label>
                <select
                  value={selectedTaskCategory}
                  onChange={(e) => setSelectedTaskCategory(e.target.value)}
                  className="w-full px-3 py-2 bg-white/90 border border-pink-100/50 rounded-2xl focus:outline-none focus:ring-2 focus:ring-[#FF4D8D]/20"
                  required
                >
                  <option value="" disabled>Select a category</option>
                  {categories.map((cat) => (
                    <option key={cat.id} value={cat.id}>
                      {cat.title}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div className="flex justify-end gap-3 pt-4">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-gray-600 hover:text-gray-800 text-sm transition-colors rounded-2xl hover:bg-gray-100/50"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-gradient-to-r from-[#FF4D8D] to-[#FF7F8E] text-white text-sm rounded-2xl hover:from-[#FF3D7D] hover:to-[#FF6F7E] transition-all shadow-sm hover:shadow-[0_2px_8px_rgba(255,77,141,0.3)]"
              >
                {editTask ? 'Update Task' : 'Add Task'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>,
    document.body
  );
};