import React, { useState, useEffect, useRef } from 'react';
import { DragDropContext } from '@hello-pangea/dnd';
import { Plus } from 'lucide-react';
import { KanbanColumn } from './KanbanColumn.tsx';
import { handleDragEnd } from './utils.ts';
import { AddTaskModal } from './AddTaskModal.tsx';
import { DeleteZone } from './DeleteZone.tsx';
import { CategorySelector } from './CategorySelector.tsx';
import { Task, TaskBoardCategories } from './types.ts';
import { getTasks, saveTask } from '../../../utils/tasks.ts'
import { categories as taskCategories } from '../weddingPlan/WeddingPlanSummary.tsx';
import { updateTask, deleteTask } from '../../../utils/tasks.ts'

const categories = [
  { id: 'all', title: "All", icon: '📋' },
  { id: 'venue', title: 'Venue & Ceremony', icon: '🏰' },
  { id: 'website', title: 'Wedding Website', icon: '💻' },
  { id: 'photo', title: 'Photos & Videos', icon: '📸' },
  { id: 'food', title: 'Food & Drink', icon: '🍽️' },
  { id: 'attire', title: 'Attire', icon: '👗' },
  { id: 'music', title: 'Music', icon: '🎵' },
  { id: 'flowers', title: 'Flowers & Decor', icon: '💐' },
  { id: 'invites', title: 'Invitations & Paper', icon: '✉️' },
  { id: 'planning', title: 'Planning & Admin', icon: '📋' },
  { id: 'honeymoon', title: 'Honeymoon', icon: '✈️' },
];

// Define the Column type
interface Column {
  id: string;
  title: string;
  tasks: Task[]; // Ensure tasks is of type Task[]
}

export const KanbanBoard: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDraggingTask, setIsDraggingTask] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string>('all');
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const originalParentRef = useRef<HTMLElement | null>(null);
  const [taskToEdit, setTaskToEdit] = useState<Task | null>(null);

  const [columns, setColumns] = useState<Column[]>([
    { id: 'TODO', title: 'To Do', tasks: [] },
    { id: 'IN_PROGRESS', title: 'In Progress', tasks: [] },
    { id: 'DONE', title: 'Done', tasks: [] }
  ]);

  useEffect(() => {
    const fetchTasks = async () => {
      const tasks = await getTasks(); // Fetch tasks from the API
      if (tasks && tasks.length > 0) {
        let mappedTasks = tasks.map((task: Task) => ({
          id: task.id, // Use the actual task ID
          name: task.name, // Assuming task has a title property
          description: task.description, // Assuming task has a description property
          priority: task.priority || 'MEDIUM', // Use task priority or default to 'MEDIUM'
          status: task.status || 'TODO', // Use task status or default to 'TODO'
          category_id: task.taskBoard,
          dueDate: task.dueDate || null,
        }));


        if (selectedCategory !== "all") {
          mappedTasks = mappedTasks.filter((task: { category_id: string; }) =>
            task.category_id.toLowerCase().includes(selectedCategory.toLowerCase())
          );
        }

        setColumns([
          { id: 'TODO', title: 'To Do', tasks: mappedTasks.filter((task: { status: string; }) => task.status === 'TODO') },
          { id: 'IN_PROGRESS', title: 'In Progress', tasks: mappedTasks.filter((task: { status: string; }) => task.status === 'IN_PROGRESS') },
          { id: 'DONE', title: 'Done', tasks: mappedTasks.filter((task: { status: string; }) => task.status === 'DONE') }
        ]);
      }
    };

    fetchTasks(); // Call the async function
  }, [selectedCategory]); // Add selectedCategory to the dependency array

  const onDragStart = (start: any) => {
    setIsDraggingTask(true);
    const draggedElement = document.querySelector(`[data-rfd-draggable-id='${start.draggableId}']`) as HTMLElement;
    if (draggedElement) {
      // Store the original parent
      originalParentRef.current = draggedElement.parentElement;

      const draggedRect = draggedElement.getBoundingClientRect();
      const wrapperRect = wrapperRef.current?.getBoundingClientRect();

      // Store original position before moving the element
      const originalX = draggedRect.left;
      const originalY = draggedRect.top;

      // Move element to new parent
      if (wrapperRef.current) {
        wrapperRef.current.appendChild(draggedElement);
        draggedElement.style.position = 'absolute';

        // Get source column's scroll position
        const sourceColumn = document.querySelector(`[data-rfd-droppable-id='${start.source.droppableId}']`);
        const sourceScrollTop = sourceColumn ? sourceColumn.scrollTop : 0;

        // Calculate position relative to new parent, accounting for scroll
        const newX = originalX - (wrapperRect?.left || 0);
        const newY = originalY - (wrapperRect?.top || 0) - sourceScrollTop;

        // Set position relative to new parent
        draggedElement.style.left = `${newX + 30}px`;
        draggedElement.style.top = `${newY + 90}px`;
      }
    }
  };

  const onDragEnd = async (result: any) => {
    setIsDraggingTask(false);
    const draggedElement = document.querySelector(`[data-rfd-draggable-id='${result.draggableId}']`) as HTMLElement;

    if (!result.destination || !result.destination.droppableId) {
      // Get original column element
      const sourceColumnElement = document.querySelector(`[data-rfd-droppable-id='${result.source.droppableId}']`);
      
      if (sourceColumnElement && draggedElement && wrapperRef.current) {
        const columnItems = Array.from(sourceColumnElement.querySelectorAll('[data-rfd-draggable-id]'));
        const nextItem = columnItems[result.source.index];
        if (nextItem) {
          sourceColumnElement.insertBefore(draggedElement, nextItem);
        } else {
          sourceColumnElement.appendChild(draggedElement);
        }
      }
      return;
    }

    const destinationColumnElement = document.querySelector(`[data-rfd-droppable-id='${result.destination.droppableId}']`);

    if (destinationColumnElement === originalParentRef.current) {
      // Return to original parent only if no destination
      if (draggedElement && originalParentRef.current) {
        originalParentRef.current.appendChild(draggedElement);
        draggedElement.style.position = 'relative';
        draggedElement.style.left = 'auto';
        draggedElement.style.top = 'auto';
      }

      // Update columns state for same column reordering
      const newColumns = handleDragEnd(result, columns);
      if (newColumns) {
        setColumns(newColumns);
      }
      return;
    }

    // Find the destination column DOM element
    if (draggedElement && destinationColumnElement && originalParentRef.current) {
      originalParentRef.current.appendChild(draggedElement);
      draggedElement.style.visibility = 'hidden';
      draggedElement.style.position = 'absolute';
      draggedElement.style.left = 'auto';
      draggedElement.style.top = 'auto';
    }
    const sourceColumn = columns.find(col => col.id === result.source.droppableId);
    const destinationColumn = columns.find(col => col.id === result.destination.droppableId);

    if (!sourceColumn) return;
    // Handle deletion if dropped in a delete zone
    if (result.destination.droppableId === 'delete-zone') {
      const taskToDelete = sourceColumn.tasks[result.source.index]; // Get the task to delete
      const taskIdToDelete = taskToDelete.id
      const newColumns = columns.map(col => {
        if (col.id === result.source.droppableId) {
          const newTasks = [...col.tasks];
          newTasks.splice(result.source.index, 1);
          return { ...col, tasks: newTasks };
        }
        return col;
      });
      await deleteTask(taskIdToDelete)
      setColumns(newColumns);

      return;
    }
    if (!destinationColumn) return;

    // Update the task's status based on the destination column
    const movedTask = sourceColumn.tasks[result.source.index];
    const updatedTask: Partial<Task> = { status: destinationColumn.id as any }; // Update status to the new column ID

    await updateTask(movedTask.id, updatedTask);

    const newColumns = handleDragEnd(result, columns); // Pass the updated task to handleDragEnd

    if (newColumns) {
      setColumns(newColumns);
    }
  };

  const addTasks = async (taskData: Partial<Task>) => {
    const tasks = await getTasks();
    const lastTaskId = tasks.reduce((max, task) => Math.max(max, task.id), 0);


    const newTask: Task = {
      id: lastTaskId + 1,
      name: taskData.name || '',
      orderIndex: 1,
      description: taskData.description || '',
      priority: taskData.priority || 'MEDIUM',
      dueDate: taskData.dueDate || null,
      status: 'TODO',
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      taskBoard: TaskBoardCategories[taskCategories.findIndex(category => 
        category.id === (selectedCategory === 'all' ? taskData.taskBoard : selectedCategory)
      )],
    };

    await saveTask(newTask);
    setColumns(prevColumns => prevColumns.map(col =>
      col.id === 'TODO'
        ? { ...col, tasks: [...col.tasks, newTask] }
        : col
    ));
  };

  const handleAddTask = async (taskData: Partial<Task>) => {
    if (taskData.id) {
      await updateTasks(taskData)
    }
    else {
      await addTasks(taskData);
    }
    setIsModalOpen(false);
  };

  const handleEdit = (task: Task) => {
    setTaskToEdit(task);
    setIsModalOpen(true);
  };

  const updateTasks = async (taskData: Task) => {
    const updatedTask: Task = {
      name: taskData.name,
      description: taskData.description || '',
      priority: taskData.priority || 'MEDIUM',
      dueDate: taskData.dueDate || null,
      updated_at: new Date().toISOString(),
    };
    await updateTask(taskData.id, updatedTask);
    setColumns(prevColumns => prevColumns.map(col => ({
      ...col,
      tasks: col.tasks.map(task => 
        task.id === taskData.id ? { ...task, ...updatedTask } : task
      )
    })));
  };

  return (
    <>
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <div className="space-y-4">
          <div className="flex items-center justify-between gap-2 mb-4 sm:mb-0">
            <div className="flex items-center gap-2">
              <CategorySelector
                categories={categories}
                selectedCategory={selectedCategory}
                onSelectCategory={setSelectedCategory}
              />
              <button
                onClick={() => setIsModalOpen(true)}
                className="flex items-center justify-center gap-1.5 px-2.5 py-1.5 bg-[#E74C88] text-white rounded-lg hover:bg-[#d43d77] shadow-sm hover:shadow-md transition-all duration-200 text-xs"
              >
                <Plus className="w-3.5 h-3.5" />
                Add Task
              </button>
            </div>
            <DeleteZone isDraggingTask={isDraggingTask} />
          </div>

          <div ref={wrapperRef} className="flex flex-col sm:flex-row gap-2 sm:gap-4 h-auto sm:h-[calc(100vh-28rem)] px-2 sm:px-1 pt-2 sm:pt-1">
            {columns.map(column => (
              <KanbanColumn
                key={column.id}
                column={column}
                onEdit={handleEdit}
                className="bg-white shadow-sm hover:shadow-md transition-shadow"
              />
            ))}
          </div>
        </div>
      </DragDropContext>
      <AddTaskModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setTaskToEdit(null);
        }}
        onAddTask={handleAddTask}
        editTask={taskToEdit}
        selectedCategory={selectedCategory}
        showCategoryDropdown={selectedCategory === 'all'}
      />
    </>
  );
};