import React, { useEffect, useState } from 'react';
import { format, addMonths, startOfYear, eachMonthOfInterval, isSameDay, isToday, parseISO, subMonths } from 'date-fns';
import { MiniCalendar } from './MiniCalendar.tsx';
import { MonthCalendar } from './MonthCalendar.tsx';
import { CalendarHeader } from './CalendarHeader.tsx';
import { AddEventModal } from './AddEventModal.tsx';
import { Task } from '../kanban/types';
import { getTasks } from '../../../utils/tasks.ts';
import { getCalenderEvents, saveCalenderEvent } from '../../../utils/calender.ts';
import { CalendarEvent } from './types';

export const CalendarView: React.FC = () => {
  const currentDate = new Date();
  const [selectedMonth, setSelectedMonth] = useState<Date | null>(null);
  const [selectedFilter, setSelectedFilter] = useState<'all' | 'tasks' | 'events'>('all');
  const [isAddEventModalOpen, setIsAddEventModalOpen] = useState(false);
  const [calendarEvents, setCalendarEvents] = useState<CalendarEvent[]>([]);
  const [allTasks, setAllTasks] = useState<Task[]>([]);
  const [selectedYear, setSelectedYear] = useState(currentDate.getFullYear());
  const [currentDateTime, setCurrentDateTime] = useState(currentDate);

  // Update current time every minute
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchTasksAndCalenderEvents = async () => {
      const tasks = await getTasks();
      const calEvents = await getCalenderEvents();
      setAllTasks(tasks);
      setCalendarEvents(calEvents);
    };
    fetchTasksAndCalenderEvents();
  }, []); 
  
  const startDate = startOfYear(new Date(selectedYear, 0, 1));
  const months = eachMonthOfInterval({
    start: startDate,
    end: addMonths(startDate, 11)
  });

  const handleAddEvent = async (event: CalendarEvent) => {
    try {
      setCalendarEvents(prev => [...prev, event]);
      await saveCalenderEvent(event);
      setIsAddEventModalOpen(false);
    } catch (error) {
      console.error('Error saving event:', error);
    }
  };

  const getEventsForDate = (date: Date) => {
    const formattedDate = format(date, 'yyyy-MM-dd');
    
    let items: (CalendarEvent | Task)[] = [];

    // Add events based on filter
    if (selectedFilter === 'all' || selectedFilter === 'events') {
      const events = calendarEvents.filter(event => {
        return format(new Date(event.startTime), 'yyyy-MM-dd') === formattedDate;
      });
      items = [...items, ...events];
    }

    // Add tasks based on filter
    if (selectedFilter === 'all' || selectedFilter === 'tasks') {
      const tasks = allTasks.filter(task => {
        if (!task.dueDate) return false;
        return format(new Date(task.dueDate), 'yyyy-MM-dd') === formattedDate;
      });
      
      // Convert tasks to calendar event format
      const taskEvents = tasks.map(task => ({
        id: task.id,
        title: task.name,
        description: task.description,
        date: format(new Date(task.dueDate!), 'yyyy-MM-dd'),
        isAllDay: true,
        priority: task.priority || 'MEDIUM',
        isTask: true // Add this flag to differentiate tasks from events
      }));
      
      items = [...items, ...taskEvents];
    }

    return items;
  };

  const getPriorityColor = (priority: 'LOW' | 'MEDIUM' | 'HIGH') => {
    switch (priority) {
      case 'HIGH':
        return 'bg-red-500';
      case 'MEDIUM':
        return 'bg-yellow-500';
      case 'LOW':
        return 'bg-green-500';
      default:
        return 'bg-gray-500';
    }
  };

  const handleYearChange = (direction: 1 | -1) => {
    setSelectedYear(prev => prev + direction);
  };

  const handleMonthChange = (direction: 1 | -1) => {
    if (selectedMonth) {
      const newMonth = direction === 1 
        ? addMonths(selectedMonth, 1)
        : subMonths(selectedMonth, 1);
      setSelectedMonth(newMonth);
    }
  };

  const isCurrentDay = (date: Date) => {
    return isSameDay(date, currentDateTime);
  };

  const renderMiniCalendar = (month: Date) => {
    return (
      <div
        key={month.toISOString()}
        onClick={() => setSelectedMonth(month)}
        className="cursor-pointer transform transition-transform hover:scale-105 rounded-lg overflow-hidden border border-gray-200"
      >
        <div className="text-center mb-1 bg-gray-50 py-2">
          <h3 className={`text-sm font-semibold ${
            month.getMonth() === currentDateTime.getMonth() && 
            month.getFullYear() === currentDateTime.getFullYear() 
              ? 'text-[#6E497E]'
              : 'text-gray-800'
          }`}>
            {format(month, 'MMMM yyyy')}
          </h3>
        </div>
        <MiniCalendar 
          month={month} 
          events={calendarEvents}
          isCurrentDay={isCurrentDay}
          getEventsForDate={getEventsForDate}
          getPriorityColor={getPriorityColor}
        />
      </div>
    );
  };

  return (
    <div className="h-[calc(100vh-14rem)] bg-white overflow-hidden">
      <div className="sticky top-0 z-10 bg-white/80 backdrop-blur-sm mb-4 p-4">
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-start sm:justify-between gap-3 sm:gap-0">
          <div className="flex items-center justify-between w-full">
            <CalendarHeader
              selectedFilter={selectedFilter}
              onFilterChange={setSelectedFilter}
              onAddEvent={() => setIsAddEventModalOpen(true)}
              onYearChange={handleYearChange}
              onMonthChange={handleMonthChange}
              selectedYear={selectedYear}
              isMonthView={!!selectedMonth}
            />
          </div>
        </div>
      </div>
      
      {selectedMonth ? (
        <div className="h-[calc(100%-4rem)] px-4">
          <button
            onClick={() => setSelectedMonth(null)}
            className="mb-2 text-[#6E497E] hover:text-[#5d3d6b] flex items-center text-sm sm:text-base transition-colors"
          >
            ← Back to Year View
          </button>
          <div className="h-[calc(100%-2rem)]">
            <MonthCalendar 
              month={selectedMonth} 
              events={calendarEvents}
              isCurrentDay={isCurrentDay}
              getEventsForDate={getEventsForDate}
              getPriorityColor={getPriorityColor}
            />
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 overflow-y-auto pb-16 px-4 max-h-[calc(100vh-16rem)]">
          {months.map(renderMiniCalendar)}
        </div>
      )}

      <AddEventModal
        isOpen={isAddEventModalOpen}
        onClose={() => setIsAddEventModalOpen(false)}
        onAddEvent={handleAddEvent}
      />
    </div>
  );
};

export default CalendarView;