import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate, Navigate, useLocation } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { DesignProvider } from './hooks/DesignContext';
import Home from './pages/landingPage/Home.js';
import Decor from './pages/design/decor/Decor.js';
import Explore from './pages/explore/Explore.js';
import LoginPage from './pages/login/LoginPage.js';
import UserProfile from './pages/profile/UserProfile.js';
import PrivacyPolicy from './pages/privacyPolicy/PrivacyPolicy.jsx';
import TermsOfService from './pages/termsOfService/TermsOfService.jsx';
import PricingPlan from './pages/pricingPlan/PricingPlan.jsx';
import './App.css';
import { UserCheck } from 'lucide-react';
import DesignOptions from './pages/design/main/DesignOptions.js';
import Florals from './pages/design/floral/Florals.js';
import WeddingCakes from './pages/design/weddingCake/WeddingCakes.js';
import { fetchCredits } from './utils/credits';
import Proposals from './pages/design/proposal/Proposals.js';
import WeddingAttire from './pages/design/attire/WeddingAttire.js';
import MobileHeader from './components/header/MobileHeader.js';
import DesktopHeader from './components/header/DesktopHeader.js';
import PlanPage from './pages/plan/Plan.tsx';
import { TaskProvider } from './context/TaskContext.tsx';
import { BudgetProvider } from './context/BudgetContext.tsx';
import VendorOnboarding from './pages/onboarding/VendorOnboarding';

const App = () => {
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [userType, setUserType] = useState(null);
  const [credits, setCredits] = useState(null);
  const [showCreditsModal, setShowCreditsModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const mainContentRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      setIsScrolled(false);
      setIsInitialized(false);
    };

    window.addEventListener('popstate', handleRouteChange);
    return () => window.removeEventListener('popstate', handleRouteChange);
  }, []);

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();

    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        if (!currentUser.emailVerified) {
          console.log('Email not verified');
        }
        setUser(currentUser);
        fetchCredits(currentUser);

        try {
          const userDocRef = doc(db, 'users', currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setUserType(userData.userType);

            if (currentUser.emailVerified !== userData.emailVerified) {
              await updateDoc(userDocRef, {
                emailVerified: currentUser.emailVerified,
              });
            }
          }
        } catch (err) {
          setError('Failed to fetch user data');
          setUserType(null);
        }
      } else {
        setUser(null);
        setUserType(null);
        setCredits(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleAuthAction = () => {
    if (user) {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          navigate('/');
          setMenuOpen(false);
        })
        .catch(() => {
          setError('Failed to log out');
        });
    } else {
      navigate('/login');
      setMenuOpen(false);
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const NavigationLinks = ({ isMobile }) => (
    <>
      {location.pathname !== '/' && (
        <li>
          <Link 
            to="/" 
            className="nav-link" 
            onClick={() => setMenuOpen(false)}
          >
            Home
          </Link>
        </li>
      )}
      {user && (
        <>
         <li>
            <Link
              to="/plan"
              className="nav-link"
              onClick={() => setMenuOpen(false)}
            >
              Plan
            </Link>
          </li>
          <li>
            <Link
              to="/design"
              className="nav-link"
              onClick={() => setMenuOpen(false)}
            >
              Design
            </Link>
          </li>
          <li>
            <Link
              to="/explore"
              className="nav-link"
              onClick={() => setMenuOpen(false)}
            >
              Explore
            </Link>
          </li>
          <li>
            <Link
              to="/user-profile"
              className="nav-link profile-link"
              onClick={() => setMenuOpen(false)}
            >
              <div className="profile-icon">
                <UserCheck size={32} />
              </div>
              
            </Link>
          </li>
        </>
      )}
      {!isMobile && (
        <li>
          <button
            onClick={handleAuthAction}
            className="auth-button px-6 py-3 rounded-full bg-gradient-to-r from-rose-300 to-amber-200 hover:opacity-90 text-white font-medium"
          >
            {user ? 'Logout' : 'Sign Up'}
          </button>
        </li>
      )}
    </>
  );

  useEffect(() => {
    const mainContent = mainContentRef.current;
    if (mainContent) {
      mainContent.scrollTo(0, 0);
    }
  }, [location.pathname]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <TaskProvider>
      <BudgetProvider>
        <DesignProvider>
          <div className="app">
            <div className="app-container">
              <header
                className={`header fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
                  location.pathname.includes('/design') || 
                  location.pathname.includes('/user-profile') || 
                  location.pathname.includes('/explore') ||
                  location.pathname.includes('/plan') ||
                  location.pathname.includes('/login')
                    ?'bg-[#1A1625]/95 backdrop-blur-sm'
                    : location.pathname === '/' && isScrolled
                      ? 'bg-[#1A1625]/95 backdrop-blur-sm'
                      : 'bg-transparent'
                }`}
              >
                <Link to="/" className="title-link flex items-center gap-2">
                  <img 
                    src="/logos/full_band_1.svg" 
                    alt="Wedify Logo" 
                    className="w-14 h-14 drop-shadow-lg hover:scale-110 transition-transform duration-300 filter brightness-110"
                  />
                  <h2 className="title">
                       Wedify
                  </h2>
                </Link>
                {isMobile ? (
                  <MobileHeader
                    setShowCreditsModal={setShowCreditsModal}
                    showCreditsModal={showCreditsModal}
                    menuOpen={menuOpen}
                    toggleMenu={toggleMenu}
                    user={user}
                  />
                ) : (
                  <DesktopHeader user={user} handleAuthAction={handleAuthAction} />
                )}
              </header>

              {isMobile && (
                <nav className={`nav mobile ${menuOpen ? 'open' : ''}`}>
                  <div className="empty-space" />
                  <ul className="nav-list">
                    <NavigationLinks isMobile={true} />
                  </ul>
                  <button onClick={handleAuthAction} className="auth-button mobile">
                    {user ? 'Logout' : 'Sign Up'}
                  </button>
                </nav>
              )}
              <main 
                className="main-content" 
                ref={mainContentRef}
                onScroll={(e) => {
                  const scrolled = e.currentTarget.scrollTop > 50;
                  setIsScrolled(scrolled);
                }}
              >
                <div className="scrollable-content">
                  {error && <div style={{ color: 'red' }}>{error}</div>}
                  <Routes>
                    <Route
                      path="/"
                      element={<Home containerRef={mainContentRef} />}
                    />
                    <Route path="/login" element={<LoginPage />} />
                    <Route
                      path="/design"
                      element={user ? <DesignOptions /> : <Navigate to="/login" />}
                    />
                    <Route
                      path="/design/decor"
                      element={
                        user ? (
                          <Decor
                            userType={userType}
                            credits={credits}
                            setCredits={setCredits}
                            showCreditsModal={showCreditsModal}
                            setShowCreditsModal={setShowCreditsModal}
                          />
                        ) : (
                          <Navigate to="/login" />
                        )
                      }
                    />
                    <Route
                      path="/design/florals"
                      element={
                        user ? <Florals userType={userType} /> : <Navigate to="/login" />
                      }
                    />
                    <Route
                      path="/design/weddingcake"
                      element={
                        user ? <WeddingCakes /> : <Navigate to="/login" />
                      }
                    />
                    <Route
                      path="/design/proposals"
                      element={
                        user ? <Proposals /> : <Navigate to="/login" />
                      }
                    />
                    <Route
                      path="/design/attire"
                      element={
                        user ? <WeddingAttire /> : <Navigate to="/login" />
                      }
                    />
                    <Route
                      path="/explore"
                      element={user ? <Explore /> : <Navigate to="/login" />}
                    />
                    <Route
                      path="/user-profile"
                      element={
                        user ? (
                          <UserProfile user={user} userType={userType} />
                        ) : (
                          <Navigate to="/login" />
                        )
                      }
                    />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-of-service" element={<TermsOfService />} />
                    <Route path="/pricing-plan" element={<PricingPlan />} />
                    <Route path="/plan" element={<PlanPage />} />
                    <Route
                      path="/vendor-onboarding"
                      element={
                        user ? (
                          userType === 'vendor' ? (
                            <VendorOnboarding />
                          ) : (
                            <Navigate to="/" />
                          )
                        ) : (
                          <Navigate to="/login" />
                        )
                      }
                    />
                  </Routes>
                </div>
              </main>
            </div>
          </div>
        </DesignProvider>
      </BudgetProvider>
    </TaskProvider>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;