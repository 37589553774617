import React, { useState, useEffect } from 'react';
import {
  RefreshCw, Check, ChevronLeft, ChevronRight, Wand2, Heart, Expand, X, PlusCircle
} from 'lucide-react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { fetchUserCredits } from '../../services/creditsService';
import { sendSuggestionToDiscord } from '../../utils/discordWebhook';
import DiscordModal from '../DiscordModal';

interface DesignMobileProps {
  steps: Array<{
    id: string;
    title: string;
    type: 'grid' | 'color-picker';
    options: Array<{ name: string; image?: string; hex?: string; subOptions?: string[] }>;
    allowCustom?: boolean;
    allowMultiple?: boolean;
    maxSelections?: number;
  }>;
  selections: Record<string, any>;
  subSelections: Record<string, any>;
  previewItems: Array<{ url: string; label: string; prompt?: string }>;
  loading: boolean;
  progress: number;
  error: string;
  credits: number;
  title: string;
  onSelectionChange: (stepId: string, value: any) => void;
  onSubSelectionChange: (stepId: string, value: any) => void;
  onGenerate: () => Promise<void>;
  onSave: (item: { url: string; label: string; prompt?: string }) => Promise<void>;
}

const styles = `
  @keyframes gradient-x {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }

  .animate-gradient-x {
    background-size: 200% 200%;
    animation: gradient-x 3s ease infinite;
  }

  @keyframes loadingGradient {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
  .loading-gradient {
    background: linear-gradient(120deg, 
      rgba(255, 105, 180, 0.8),  /* #FF69B4 */
      rgba(255, 127, 80, 0.8),   /* #FF7F50 */
      rgba(255, 105, 180, 0.8)   /* #FF69B4 */
    );
    background-size: 300% 300%;
    animation: loadingGradient 4s ease infinite;
  }
`;

export function MobileDesign({
  steps,
  selections,
  subSelections,
  previewItems,
  loading,
  error,
  title,
  onSelectionChange,
  onSubSelectionChange,
  onGenerate,
  onSave,
}: DesignMobileProps) {
  const [currentStep, setCurrentStep] = useState(0);
  const [isGenerating, setIsGenerating] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [user, setUser] = useState(null);
  const [localCredits, setLocalCredits] = useState(null);
  const [savedItems, setSavedItems] = useState<Record<string, boolean>>({});
  const [simulatedProgress, setSimulatedProgress] = useState(0);
  const [expandedImage, setExpandedImage] = useState<{url: string; label: string} | null>(null);
  const [generationComplete, setGenerationComplete] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuggestionModal, setShowSuggestionModal] = useState(false);
  const [currentSuggestionStep, setCurrentSuggestionStep] = useState<{
    id: string;
    title: string;
  } | null>(null);

  // Add totalSteps calculation
  const totalSteps = steps.length + 1; // Add 1 for the generation step

  // Fetch user and credits on component mount
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        fetchUserCredits(currentUser.uid)
          .then((credits) => setLocalCredits(credits))
          .catch((error) => console.error('Error fetching credits:', error));
      }
    });

    return () => unsubscribe();
  }, []);

  // Simulate progress during loading
  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        setSimulatedProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(interval);
            return 100;
          }
          return prevProgress + 10; // Increment by 10% every second
        });
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setSimulatedProgress(0); // Reset progress when loading is complete
    }
  }, [loading]);

  // Add effect to handle error display
  useEffect(() => {
    if (error) {
      setShowError(true);
      const timer = setTimeout(() => {
        setShowError(false);
      }, 2000); // Hide after 2 seconds

      return () => clearTimeout(timer);
    }
  }, [error]);

  // Add this useEffect to load saved states from localStorage on component mount
  useEffect(() => {
    const savedState = localStorage.getItem('savedItems');
    if (savedState) {
      setSavedItems(JSON.parse(savedState));
    }
  }, []);

  // Handle next step
  const handleNext = () => {
    if (currentStep === steps.length - 1) {
      setCurrentStep(currentStep + 1);
      handleGenerate();
    } else if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  // Handle previous step
  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  // Check if the user can continue to the next step
  const canContinue = () => {
    if (currentStep >= steps.length) {
      return false;
    }
    
    const step = steps[currentStep];
    if (!step) {
      return false;
    }

    if (step.allowMultiple) {
      return selections[step.id]?.length > 0;
    }
    return !!selections[step.id];
  };

  // Handle generation
  const handleGenerate = async () => {
    try {
      setIsGenerating(true);
      setCurrentStep(steps.length); // Move to the loading step
      setShowResults(false); // Reset results before new generation
      await onGenerate(); // This should handle progress updates
      setShowResults(true);
      setIsGenerating(false);
      setGenerationComplete(true);
    } catch (error) {
      console.error('Generation error:', error);
      setIsGenerating(false);
    }
  };

  // Update handleStepClick to allow navigation to all steps after generation
  const handleStepClick = (index: number) => {
    if (generationComplete) {
      setCurrentStep(index);
      setShowResults(false); // Hide results when going back to steps
    } else if (index <= currentStep || index === currentStep + 1) {
      setCurrentStep(index);
    }
  };

  // Add this helper function to determine if the step is clickable
  const isStepClickable = (index: number) => {
    if (generationComplete) {
      return true; // All steps are clickable after generation
    }
    return index <= currentStep || index === currentStep + 1;
  };

  // Add handler functions for suggestions
  const handleAddClick = (stepId: string, stepTitle: string) => {
    setCurrentSuggestionStep({ id: stepId, title: stepTitle });
    setShowSuggestionModal(true);
  };

  const handleSuggestionSubmit = async (suggestionData: any) => {
    try {
      const enrichedData = {
        ...suggestionData,
        stepId: currentSuggestionStep?.id,
        category: title,
        subcategory: currentSuggestionStep?.title
      };
      
      await sendSuggestionToDiscord(enrichedData);
      setShowSuggestionModal(false);
    } catch (error) {
      console.error('Failed to submit suggestion:', error);
      throw new Error('Failed to submit suggestion');
    }
  };


  // Modify the loading/progress section
  const renderLoading = () => (
    <div className="fixed inset-0 bg-white overflow-y-auto">
      <div className="min-h-screen flex flex-col items-center justify-center p-6 pb-20"> {/* Added pb-20 for extra bottom padding */}
        <div className="space-y-6 text-center mb-8">
          <h2 className="text-2xl font-bold bg-gradient-to-r from-[#FF69B4] to-[#FF7F50] bg-clip-text text-transparent">
            Transform Your Wedding Vision
          </h2>
          <p className="text-base text-gray-600 max-w-xs mx-auto">
            Our AI-powered design assistant is creating your perfect wedding atmosphere
          </p>
        </div>

        {/* Loading Container */}
        <div className="w-full aspect-square max-w-xs mx-auto rounded-3xl relative overflow-hidden bg-[#FF69B4] mb-8"> {/* Added mb-8 for spacing */}
          <div className="loading-gradient absolute inset-0" />
          
          {/* Image Icon */}
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20">
            <svg className="w-8 h-8 text-white opacity-80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M21 3H3C1.89543 3 1 3.89543 1 5V19C1 20.1046 1.89543 21 3 21H21C22.1046 21 23 20.1046 23 19V5C23 3.89543 22.1046 3 21 3Z" />
              <path d="M8.5 10C9.32843 10 10 9.32843 10 8.5C10 7.67157 9.32843 7 8.5 7C7.67157 7 7 7.67157 7 8.5C7 9.32843 7.67157 10 8.5 10Z" />
              <path d="M21 15L16 10L5 21" />
            </svg>
          </div>

          {/* Progress Bar */}
          <div className="absolute bottom-8 left-1/2 -translate-x-1/2 w-4/5 z-20">
            <div className="relative w-full h-1 bg-white/30 rounded-full overflow-hidden">
              <div 
                className="absolute top-0 left-0 h-full bg-white rounded-full transition-all duration-300 ease-in-out" 
                style={{ width: `${simulatedProgress}%` }}
              />
            </div>
            <div className="mt-4 text-center">
              <span className="text-white text-base font-medium">{simulatedProgress}%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  // Render step content
  const renderStepContent = () => {
    if (currentStep >= steps.length) {
      return (
        <div className="space-y-6">
          {loading ? (
            renderLoading()
          ) : previewItems && previewItems.length > 0 ? (
            <div className="w-full ">
              {previewItems.map((item, index) => (
                <div key={index} className="relative mb-8">
                  <img
                    src={item.url}
                    alt={item.label}
                    onClick={() => setExpandedImage(item)}
                    className="w-full rounded-lg shadow-lg cursor-pointer transition-transform hover:scale-[1.02]"
                  />
                  <button
                    onClick={() => setExpandedImage(item)}
                    className="absolute bottom-4 left-4 z-[1] px-4 py-2 bg-white/80 backdrop-blur-sm rounded-lg font-medium transition-all flex items-center justify-center gap-1.5 text-gray-700 text-sm hover:bg-white"
                  >
                    <Expand className="w-4 h-4" />
                    Expand
                  </button>
                  <button
                    onClick={async () => {
                      try {
                        await onSave(item);
                        // Update the saved state and persist it
                        setSavedItems(prev => {
                          const newState = {
                            ...prev,
                            [item.url]: true
                          };
                          // Optionally store in localStorage to persist across refreshes
                          localStorage.setItem('savedItems', JSON.stringify(newState));
                          return newState;
                        });
                      } catch (error) {
                        console.error('Error saving:', error);
                      }
                    }}
                    className="absolute top-4 right-4 z-40 px-4 py-2 bg-gradient-to-tr from-[#FF69B4] via-[#FF7F50] to-[#FF69B4] rounded-xl shadow-lg hover:shadow-xl transition-all flex items-center gap-1.5"
                    disabled={savedItems[item.url]} // Disable button after saving
                  >
                    <Heart className={`w-5 h-5 text-white ${savedItems[item.url] ? 'fill-white' : ''}`} />
                    <span className="text-white text-sm font-medium">
                      {savedItems[item.url] ? 'Saved to Profile' : 'Save'}
                    </span>
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center text-gray-500">
              Complete all options to generate your design
            </div>
          )}
        </div>
      );
    }

    const step = steps[currentStep];
    if (!step) {
      return null;
    }

    return (
      <div key={step.id}>
        <h2 className="text-xl font-semibold text-gray-800 mb-4 text-center">
          {step.title}
        </h2>

        {step.type === 'grid' ? (
          <>
            <div className="grid grid-cols-2 gap-4">
              {step.options.map((option) => (
                <div key={option.name} className="flex flex-col">
                  <div className="relative">
                    <button
                      onClick={() => {
                        if (option.name === 'Add') {
                          handleCustomOption(step.id);
                        } else {
                          onSelectionChange(step.id, option.name.toLowerCase());
                        }
                      }}
                      className={`relative group overflow-hidden rounded-lg aspect-square w-full transition-all duration-300 ${
                        selections[step.id]?.includes(option.name.toLowerCase())
                          ? 'ring-2 ring-black scale-105'
                          : 'hover:scale-105'
                      }`}
                    >
                      <div className="absolute inset-0">
                        {option.image && (
                          <img
                            src={option.image}
                            alt={option.name}
                            className="w-full h-full object-cover"
                          />
                        )}
                      </div>
                      <div className="absolute inset-0 bg-gradient-to-t from-black/50 via-black/20 to-transparent" />
                      <div className="absolute inset-x-0 bottom-0 p-4">
                        <span className="text-white text-lg font-semibold drop-shadow-lg">
                          {option.name}
                        </span>
                      </div>
                      {selections[step.id]?.includes(option.name.toLowerCase()) && (
                        <div className="absolute top-3 right-3">
                          <div className="bg-white rounded-full p-1">
                            <Check className="w-4 h-4 text-black" />
                          </div>
                        </div>
                      )}
                    </button>
                  </div>

                  {option.subOptions && selections[step.id]?.includes(option.name.toLowerCase()) && (
                    <select
                      value={subSelections[step.id] || ''}
                      onChange={(e) => onSubSelectionChange(step.id, e.target.value)}
                      className="mt-3 w-full p-3 border-2 border-gray-200 rounded-lg bg-white 
                      shadow-md hover:border-gray-300 focus:border-[#FF4D8D] focus:ring-2 
                      focus:ring-[#FF4D8D]/20 focus:outline-none transition-all duration-200 
                      appearance-none cursor-pointer text-gray-700 font-medium"
                      style={{
                        backgroundImage: `url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e")`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'right 1rem center',
                        backgroundSize: '1em',
                        paddingRight: '2.5rem'
                      }}
                    >
                      <option value="" className="text-gray-500">Select {option.name} Style</option>
                      {option.subOptions.map((subOption) => (
                        <option 
                          key={subOption} 
                          value={subOption.toLowerCase()}
                          className="py-2"
                        >
                          {subOption}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              ))}
            </div>
            
            {/* Add suggestion button */}
            <button
              onClick={() => handleAddClick(step.id, step.title)}
              className="w-full mt-2 py-2 border border-dashed border-[#FF4D8D]/30 rounded-md text-[#FF4D8D] transition-colors flex items-center justify-center gap-1.5 text-sm"
            >
              <PlusCircle className="w-4 h-4" />
              <span>Suggest {step.id.charAt(0).toUpperCase() + step.id.slice(1)}</span>
            </button>
          </>
        ) : step.type === 'color-picker' ? (
          <>
            <div className="grid grid-cols-4 gap-2">
              {step.options.map((color) => {
                const isSelected = selections[step.id]?.includes(color.name.toLowerCase());
                return (
                  <button
                    key={color.name}
                    onClick={() => onSelectionChange(step.id, color.name.toLowerCase())}
                    className={`w-full aspect-square rounded-lg border-2 border border-solid border-black transition-all ${
                      isSelected ? 'border-black scale-105' : 'hover:scale-105'
                    }`}
                    style={{ backgroundColor: color.hex || 'transparent' }}
                    title={color.name}
                  >
                    {isSelected && (
                      <div className="absolute inset-0 flex items-center justify-center bg-black/20 rounded-lg">
                        <Check className="w-6 h-6 text-white" />
                      </div>
                    )}
                  </button>
                );
              })}
            </div>
            
            {/* Add suggestion button */}
            <button
              onClick={() => handleAddClick(step.id, step.title)}
              className="w-full mt-2 py-2 border border-dashed border-[#FF4D8D]/30 rounded-md text-[#FF4D8D] transition-colors flex items-center justify-center gap-1.5 text-sm"
            >
              <PlusCircle className="w-4 h-4" />
              <span>Suggest {step.id.charAt(0).toUpperCase() + step.id.slice(1)}</span>
            </button>
          </>
        ) : null}
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-50 pt-16">
      <style>{styles}</style>
      {loading && !generationComplete ? (
        renderLoading()
      ) : (
        <>
          {/* Header - Not sticky */}
          <div className="bg-gradient-to-br from-[#FF69B4]/10 via-[#FF7F50]/10 to-[#FF69B4]/10 backdrop-blur-md border-b border-white/20">
            <div className="max-w-lg mx-auto px-4">
              <div className="flex items-center justify-between mb-4">
                <h1 className="text-xl font-bold bg-gradient-to-r from-[#FF69B4] to-[#FF7F50] bg-clip-text text-transparent">
                  Design Your Wedding
                </h1>
                <div className="text-sm font-medium text-[#FF69B4]">
                  Step {currentStep + 1} of {totalSteps}
                </div>
              </div>
            </div>
          </div>

          {/* Progress Bar - Sticky */}
          <div className="sticky top-16 z-50 bg-gradient-to-r from-[#FFE4E8] to-[#FFF0F3] backdrop-blur-md border-b ">
            <div className="max-w-lg mx-auto px-4 py-2">
              {!loading && ( // Only show progress bar when not loading/generating
                <div className="flex items-center gap-0">
                  {[...steps, { id: 'generation', title: 'Generated Design' }].map((step, index) => {
                    const isActive = index === currentStep;
                    const isCompleted = index < currentStep;
                    const isClickable = isStepClickable(index);
                    const isPreviousCompleted = index > 0 && index <= currentStep;

                    return (
                      <div key={step.id} className="flex-1">
                        <div className="flex flex-col items-center">
                          <div className="flex items-center w-full">
                            <div
                              className={`flex-1 h-1 md:h-1.5 rounded-none transition-all duration-500 ${
                                index === 0 ? 'invisible' : ''
                              } ${isPreviousCompleted 
                                ? 'bg-gradient-to-r from-[#FF69B4] to-[#FF7F50]' 
                                : 'bg-[#FFE4E1]'}`}
                            />
                            <div className="relative">
                              <button
                                onClick={() => handleStepClick(index)}
                                disabled={!isClickable}
                                className={`w-9 h-9 md:w-12 md:h-12 rounded-full flex items-center justify-center transition-all duration-300
                                  ${isActive 
                                    ? 'bg-white text-[#FF69B4] border-2 border-[#FFB6C1] scale-110 shadow-[0_0_12px_rgba(255,77,141,0.4)]' 
                                    : isCompleted 
                                      ? 'bg-gradient-to-r from-[#FF69B4] to-[#FF7F50] text-white' 
                                      : 'bg-[#FFE4E1] text-[#FF69B4] border-2 border-[#FFE4E1]'
                                    } 
                                  ${isClickable 
                                    ? 'cursor-pointer hover:scale-110 hover:shadow-[0_0_12px_rgba(255,77,141,0.4)]' 
                                    : 'cursor-not-allowed opacity-50'}`}
                              >
                                <span className="text-sm md:text-base font-medium">
                                  {index + 1}
                                </span>
                              </button>
                            </div>
                            <div
                              className={`flex-1 h-1 md:h-1.5 rounded-none transition-all duration-500 ${
                                index === totalSteps - 1 ? 'invisible' : ''
                              } ${isCompleted 
                                ? 'bg-gradient-to-r from-[#FF7F50] to-[#FF69B4]' 
                                : 'bg-[#FFE4E1]'}`}
                            />
                          </div>
                          <span className="text-xs mt-1 text-gray-500 capitalize">
                            {step.id}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>

          {/* Main Content */}
          <div className="px-4 max-w-lg mx-auto pb-24">
            <div className="space-y-6">
              {renderStepContent()}
            </div>
          </div>

          {/* Bottom Navigation */}
          <div className="fixed bottom-0 left-0 right-0 bg-white border-t p-4 z-[20]">
            <div className="max-w-lg mx-auto flex gap-4">
              {currentStep > 0 && currentStep < steps.length && (
                <button
                  onClick={handleBack}
                  className="w-1/4 px-6 py-3 border rounded-xl font-medium flex items-center justify-center gap-2 text-[#FF69B4] border-[#FF69B4]/20 hover:bg-[#FF69B4]/5"
                >
                  <ChevronLeft className="w-5 h-5" />
                  Back
                </button>
              )}
              <button
                onClick={() => {
                  if (currentStep === steps.length) {
                    onGenerate();
                  } else {
                    handleNext();
                  }
                }}
                disabled={(!canContinue() && !generationComplete) || loading}
                className={`${currentStep === 0 || currentStep === steps.length ? 'w-full' : 'w-3/4'} 
                  px-6 py-3 bg-gradient-to-r from-[#FF69B4] via-[#FF7F50] to-[#FF69B4] 
                  text-white rounded-xl font-medium flex items-center justify-center gap-2 
                  disabled:opacity-50 transition-all duration-300 hover:shadow-lg`}
              >
                {loading ? (
                  <>
                    <RefreshCw className="w-5 h-5 animate-spin" />
                    Generating...
                  </>
                ) : currentStep === steps.length ? (
                  <>
                    <Wand2 className="w-5 h-5" />
                    Generate Again
                  </>
                ) : currentStep === steps.length - 1 ? (
                  <>
                    <Wand2 className="w-5 h-5" />
                    Generate Design
                  </>
                ) : (
                  <>
                    Continue
                    <ChevronRight className="w-5 h-5" />
                  </>
                )}
              </button>
            </div>
          </div>
        </>
      )}

      {/* Error Toast */}
      {error && showError && (
        <div className="fixed bottom-4 left-1/2 -translate-x-1/2 bg-red-100 text-red-600 px-4 py-2 rounded-lg shadow-lg">
          {error}
        </div>
      )}

      {/* Image Modal */}
      {expandedImage && (
        <div 
          className="fixed inset-0 z-50 bg-black/90 flex items-center justify-center p-4"
          onClick={() => setExpandedImage(null)}
        >
          <div className="relative max-w-4xl w-full">
            <button
              onClick={() => setExpandedImage(null)}
              className="absolute -top-12 right-0 text-white/70 hover:text-white"
            >
              <X className="w-6 h-6" />
            </button>
            <img
              src={expandedImage.url}
              alt={expandedImage.label}
              className="w-full rounded-lg"
            />
            <div className="absolute bottom-0 inset-x-0 p-4 bg-gradient-to-t from-black/80 to-transparent rounded-b-lg">
              <p className="text-white text-lg font-medium">
                {expandedImage.label}
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Add Discord Modal */}
      <DiscordModal
        isOpen={showSuggestionModal}
        onClose={() => setShowSuggestionModal(false)}
        onSubmit={handleSuggestionSubmit}
        category={steps.id}
        subcategory={currentSuggestionStep?.title || ''}
      />
    </div>
  );
}

export default MobileDesign;