import React from 'react';
import { Heart } from 'lucide-react';

interface CarouselProps {
  previewItems: Array<{ url: string; label: string; prompt?: string }>;
  currentImageIndex: number;
  setCurrentImageIndex: (index: number) => void;
  onSave: (item: { url: string; label: string; prompt?: string }) => Promise<void>;
  savedItems: Record<string, boolean>;
  handlePrevImage: () => void;
  handleNextImage: () => void;
}

export const Carousel: React.FC<CarouselProps> = ({
  previewItems,
  currentImageIndex,
  setCurrentImageIndex,
  onSave,
  savedItems,
  handlePrevImage,
  handleNextImage,
}) => {
  const hasMultipleImages = previewItems.length > 1;

  return (
    <div id="default-carousel" className="relative w-full h-full" data-carousel="slide">
      <div className="relative h-full overflow-hidden rounded-lg">
        {previewItems.map((item, index) => (
          <div
            key={index}
            className={`absolute inset-0 transition-opacity duration-700 ease-in-out ${
              index === currentImageIndex ? 'opacity-100' : 'opacity-0'
            }`}
            data-carousel-item
          >
            <img
              src={item.url}
              alt={item.label}
              className="absolute block w-full h-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
            />
            <div className="absolute bottom-0 left-0 right-0 p-6 bg-gradient-to-t from-black/60 to-transparent">
              <p className="text-white text-xl font-medium mb-1">
                {item.label}
              </p>
            </div>
          </div>
        ))}

        <button
          onClick={() => onSave(previewItems[currentImageIndex])}
          className="absolute top-4 right-4 z-40 px-4 py-2 bg-gradient-to-tr from-[#FF69B4] via-[#FF7F50] to-[#FF69B4] rounded-xl shadow-lg hover:shadow-xl transition-all flex items-center gap-1.5"
          disabled={savedItems[previewItems[currentImageIndex].url]}
        >
          <Heart className={`w-5 h-5 text-white ${savedItems[previewItems[currentImageIndex].url] ? 'fill-white' : ''}`} />
          <span className="text-white text-sm font-medium">
            {savedItems[previewItems[currentImageIndex].url] ? 'Saved to Profile' : 'Save'}
          </span>
        </button>

        {hasMultipleImages && (
          <>
            <div className="absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">
              {previewItems.map((_, index) => (
                <button
                  key={index}
                  type="button"
                  className={`w-3 h-3 rounded-full ${
                    index === currentImageIndex ? 'bg-[#B0316E]' : 'bg-white/50'
                  }`}
                  aria-current={index === currentImageIndex}
                  aria-label={`Slide ${index + 1}`}
                  onClick={() => setCurrentImageIndex(index)}
                />
              ))}
            </div>

            <button
              type="button"
              className="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
              onClick={handlePrevImage}
            >
              <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 group-hover:bg-white/50 group-focus:ring-4 group-focus:ring-white group-focus:outline-none">
                <svg
                  className="w-4 h-4 text-white rtl:rotate-180"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 1 1 5l4 4"
                  />
                </svg>
                <span className="sr-only">Previous</span>
              </span>
            </button>
            <button
              type="button"
              className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
              onClick={handleNextImage}
            >
              <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 group-hover:bg-white/50 group-focus:ring-4 group-focus:ring-white group-focus:outline-none">
                <svg
                  className="w-4 h-4 text-white rtl:rotate-180"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 9 4-4-4-4"
                  />
                </svg>
                <span className="sr-only">Next</span>
              </span>
            </button>
          </>
        )}
      </div>
    </div>
  );
}; 